body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lowEmphasis {
  color: rgba(0, 0, 0, 0.38);
}

.sheet {
  padding: 8px 8px 16px 8px;
  width: 100%;
  height: 100%;
}

.overflow {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

/* Onboard and Notify overrides */

/* Modal background */
.bn-onboard-modal {
  background: rgba(36, 36, 36, 0.72) !important;
  position: fixed !important;
}

.bn-onboard-modal-content-header-icon {
  background: #f9f9f9;
  color: #4a4a4a;
}

/* Containers */
.bn-notify-notification,
.bn-onboard-modal-content {
  color: inherit !important;
  border-radius: 4px !important;
}

/* Modal container text */
.bn-notify-notification,
.bn-onboard-modal-content p {
  /* Mui Typography body1 */
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
}

/* Modal close buttons */
.bn-notify-notification-close-icon {
  border-radius: 2px !important;
  top: 0.5em !important;
  right: 0.5em !important;
}

.bn-onboard-modal-content-close {
  border-radius: 4px !important;
  top: 1em !important;
  right: 1em !important;
}

/* Inline buttons */
.bn-onboard-button {
  /* Mui Inline Button */
  color: rgba(233, 30, 90, 1) !important;
  border: none !important;
  border-radius: 2px !important;
  font-size: 0.9375rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.02857em !important;
  line-height: 1.75 !important;
  padding: 6px 8px !important;
  text-transform: uppercase !important;
}

.bn-onboard-button:hover {
  border-radius: 2px !important;
  background-color: rgba(233, 30, 99, 0.08) !important;
}

/* Modal link color */
.bn-onboard-select-wallet-info {
  color: rgba(233, 30, 90, 1) !important;
}

/* ReactSelect overrides */

.multi-select-filter {
  z-index: 3;
}

/* ReactTable overrides */

.ReactTable {
  border: none !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05) !important;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-right: none !important;
  padding: 6px 8px !important;
  line-height: 1.75 !important;
}

.ReactTable .rt-thead .rt-th {
  color: rgba(0, 0, 0, 0.55) !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(233, 30, 90, 1) !important;
  color: rgba(233, 30, 90, 1) !important;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(233, 30, 90, 1) !important;
  color: rgba(233, 30, 90, 1) !important;
}

.ReactTable .rt-tbody .rt-tr-group {
  min-height: 64px;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #f9f9f9;
}

.ReactTable .rt-td {
  border-right: none !important;
  padding: 12px 4px !important;
}

.ReactTable .-pagination {
  box-shadow: none !important;
  border-top: none !important;
  margin-top: 2rem !important;
}

.ReactTable .-pagination .-btn {
  font-family: inherit !important;
  text-transform: uppercase !important;
  letter-spacing: 0.02857em !important;
  line-height: 1.75 !important;
  /* 	width: auto !important; */
  min-width: 64px !important;
  padding: 6px 16px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: transparent !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
}

.ReactTable .-pagination .-btn[disabled] {
}

.ReactTable .rt-tfoot {
  box-shadow: none !important;
  border-top: solid 1px rgba(0, 0, 0, 0.05) !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05) !important;
}

.ReactTable .rt-td:hover {
  color: black;
}

.ReactTable .rt-tr-group .show-on-row-hover {
  visibility: hidden;
}

.ReactTable .rt-tr-group:hover .show-on-row-hover {
  visibility: visible;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05) !important;
}

#selectedFromDate-label,
#selectedToDate-label {
  background-color: white;
}
